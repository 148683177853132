<template>
    <div class="team-section pt-100 pb-70">
        <div class="container1"  >
            <div class="row" style="display: flex; justify-content: center; align-items: center;">
                <div class="col-12 col-md-6 col-lg-4 pb-30">
                    <div class="team-card">
                        <div class="team-card-thumb" style="text-align: center;">
                            <img src="../../assets/images/team/hendrik.jpg" alt="team" >
                            <!-- <ul class="team-social-list">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul> -->
                        </div>
                        <div class="team-card-content">
                            <h3>Hendrik Sardjoe</h3>
                            <h4>{{ $t('team-title') }}</h4>
                            <p>{{ $t('team-desc') }}</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 col-md-6 col-lg-4 pb-30">
                    <div class="team-card">
                        <div class="team-card-thumb">
                            <img src="../../assets/images/team/khalid.jpeg" alt="team">
                                <ul class="team-social-list">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div class="team-card-content">
                            <h3>Khalid Nizami</h3>
                            <h4>COO</h4>
                            <p>Khalid Nizami serves as our Chief Operating Officer (COO),
                                overseeing the operational aspects of Infizen. With a focus on streamlining
                                processes and enhancing efficiency, Khalid ensures that our projects are delivered on
                                time and within budget. His strong organizational skills and strategic mindset contribute
                                to the seamless execution of our software development initiatives, enabling us to
                                exceed client expectations.</p>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-12 col-md-6 col-lg-4 pb-30">
                    <div class="team-card">
                        <div class="team-card-thumb">
                            <img src="../../assets/images/team/dev.jpeg" alt="team">
                             <ul class="team-social-list">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul> 
                        </div>

                        <div class="team-card-content">
                            <h3>Dev Patel</h3>
                            <h4>CTO</h4>
                            <p>Dev Patel, our brilliant Chief Technology Officer (CTO), is responsible
                                for driving our technical vision and overseeing our development processes. With a

                                strong background in AI and ML, Dev stays at the forefront of emerging technologies,
                                ensuring that we deliver cutting-edge solutions to our clients. Her expertise and
                                strategic insights enable us to leverage the latest advancements and maintain a
                                competitive edge in the industry.</p>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-12 col-md-6 col-lg-4 pb-30">
                    <div class="team-card">
                        <div class="team-card-thumb">
                            <img src="../../assets/images/team/debu.jpeg" alt="team">
                             <ul class="team-social-list">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul> 
                        </div>
                        <div class="team-card-content">
                            <h3>Debu Das</h3>
                            <h4>Director of Technical Operations</h4>
                            <p>Debu Das, our Director of Technical
                                Operations, who plays a crucial role in ensuring smooth and efficient project execution.
                                With a keen eye for detail and extensive experience in managing complex technical
                                projects, Debu oversees our operations, from resource allocation to project planning.
                                His expertise in optimizing processes and fostering collaboration among team members
                                enables us to deliver projects on time and with exceptional quality.</p>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-12 col-md-6 col-lg-4 pb-30">
                    <div class="team-card">
                        <div class="team-card-thumb">
                            <img src="../../assets/images/team/shagufta.jpeg" alt="team">
                             <ul class="team-social-list">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div class="team-card-content">
                            <h3>Shagufta Shaikh</h3>
                            <h4>CFO</h4>
                            <p>Shagufta,our Chief Financial Officer, Who is responsible for managing our financial
                                operations. With a keen eye for detail and deep financial acumen, Shagufta ensures the
                                financial stability and growth of Infizen.</p>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>

        <div class="choose-section pt-100 pb-70">
            <div class="container">
                <div class="section-title section-title-lg">

                    <h2>The Heart of Our Organization</h2>
                    <p>Our team consists of many other talented individuals who work collaboratively to deliver
                        exceptional software solutions. Together, we share a common goal of driving our clients&#39;
                        success through innovative technology and unparalleled customer service.
                        At Infizen, we are proud of our team&#39;s expertise, dedication, and commitment to
                        excellence. We continually strive to stay ahead of the curve and exceed industry standards,
                        providing our clients with outstanding software development solutions.
                        Contact us today to experience the expertise and professionalism of our team firsthand. We
                        look forward to partnering with you on your next software project.</p>
                </div>
                <!-- Contact-info -->
                <div class="contact-info mb-15">
                    <div class="row">
                        <div class="col-md-6 col-lg-4 pb-30">
                            <div class="contact-info-item">
                                <div class="contact-info-header">
                                    <div class="contact-info-header-icon">
                                        <i class="icofont-location-pin"></i>
                                    </div>
                                    <div class="contact-info-header-text">
                                        <h3>Location</h3>
                                        <p>Visit Our Office</p>
                                    </div>
                                </div>
                                <div class="contact-info-body">
                                    <p>Dr. Sophie Redmond Straat 244A</p>
                                    <p>Paramaribo, Suriname</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 pb-30">
                            <div class="contact-info-item">
                                <div class="contact-info-header">
                                    <div class="contact-info-header-icon">
                                        <i class="icofont-ui-call"></i>
                                    </div>
                                    <div class="contact-info-header-text">
                                        <h3>Make A Call</h3>
                                        <p>Let’s Talk with our Experts</p>
                                    </div>
                                </div>
                                <div class="contact-info-body">

                                    <!-- <p> <img src="../../assets/images/india.png" style="height: 1.5rem;" alt="shape"> <a href="tel:091-999-801-0990">999-801-0990</a> / <a href="tel:091-999-845-5066">999-845-5066</a></p> -->
                                    <p><img src="../../assets/images/suriname.jpg" style="height: 1.5rem;" alt="shape"> <a
                                            href="tel:+597 7171550">+597 7171550</a></p>
                                    <p><img src="../../assets/images/suriname.jpg" style="height: 1.5rem;" alt="shape"> <a
                                            href="tel:+597 8898811">+597 8898811</a></p>
                                    <p>Mon - Fri: 09.00 to 17.00</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 pb-30 offset-lg-0 offset-md-3">
                            <div class="contact-info-item">
                                <div class="contact-info-header">
                                    <div class="contact-info-header-icon">
                                        <i class="icofont-email"></i>
                                    </div>
                                    <div class="contact-info-header-text">
                                        <h3>Send An Email</h3>
                                        <p>Don’t Hesitate to Email</p>
                                    </div>
                                </div>
                                <div class="contact-info-body">
                                    <p>
                                        <a href="mailto:info@infizeninformatics.com">info@infizeninformatics.com</a>
                                    </p>
                                    <!-- <p>
                                        <a href="mailto:support@lada.com">support@dgtalworld.com</a>
                                    </p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Contact-info -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Team'
}
</script>